/* global */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* uikit */

.uk-card {
  border-radius: 12px;
  box-shadow: none;
}

.uk-card.uk-card-hover {
  filter: drop-shadow(2px 1px 2px #e0e0e0);
}

.uk-card.uk-card-hover:hover {
  filter: drop-shadow(4px 2px 4px #e0e0e0);
  box-shadow: none;
}

.uk-input,
.uk-select,
.uk-textarea {
  border-radius: 4px;
}

.uk-navbar-dropdown {
  border-radius: 12px;
}

.uk-button {
  border-radius: 4px;
}

.uk-open > .uk-modal-dialog {
  border-radius: 16px;
}

.uk-open .uk-modal-header,
.uk-open .uk-modal-footer {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.uk-navbar-item {
  padding: 0 8px;
}

.uk-form-label {
  font-weight: bold;
}

.uk-label {
  font-size: 0.75rem;
  padding: 0 8px;
}

button[disabled="disabled"].uk-icon-button,
button:disabled.uk-icon-button {
  color: #ddd;
}

/* custom */

.uk-card-muted {
  background-color: #f8f8f8 !important;
}

.uk-icon-button.nav {
  background-color: white;
  color: #1e87f0;
}

.uk-icon-button.active {
  background-color: #1e87f0;
  color: #fff;
}

a.upvote {
  color: #1e87f0 !important;
}

a.downvote {
  color: red !important;
}

img.small,
video.small {
  max-height: 100px;
}

object.small,
embed.small {
  height: 100px;
}

img.medium,
video.medium {
  max-height: 300px;
}

object.medium,
embed.medium {
  height: 300px;
}

audio.full {
  width: 100%;
}

iframe.full,
object.full,
embed.full {
  width: 100%;
  height: 100vh;
}

@media (min-width: 960px) {
  .sticky {
    position: sticky;
    top: 0;
  }
}

.reply {
  border: 1px solid #e5e5e5;
  padding: 4px 8px;
  border-radius: 4px;
}

.reply:hover {
  text-decoration: underline;
  color: #e5e5e5;
  background-color: #e5e5e5;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.avatar-small {
  width: 40px;
  height: 40px;
}

.truncate {
  width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* react select */

.react-select__placeholder {
  font-size: 0.875rem;
  color: #999;
}

.react-select__control {
  border: 1px solid #e5e5e5 !important;
}

/* videojs-record */

@media (min-width: 640px) {
  .video-js .vjs-control-bar {
    height: 3rem !important;
  }

  .video-js .vjs-control,
  .video-js .vjs-time-control,
  .vjs-icon-placeholder {
    font-size: 1rem !important;
  }
}

.vjs-record.video-js .vjs-control.vjs-record-indicator {
  left: 6ex;
}

.vjs-live .vjs-time-divider {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 0px !important;
}
